import Vue from 'vue'
import {formatAddressInput, StateFilters} from '~/util/format-filter-data'
import {AddressInterface} from '~/components/filter/address-search.vue'
import {parseQueryParams} from '~/util/query-parser'

export default Vue.extend({
  methods: {
    async parseQueryParams(query: { [key: string]: string }): Promise<StateFilters> {
      let filters = parseQueryParams(query);

      if (filters.address && filters.address.fullTitle) {
        const searchResult = await this.searchAddress(filters.address.fullTitle);

        if (searchResult.length > 0) {
          filters.address = searchResult[0];
        }
      }

      return filters;
    },
    async searchAddress(input: string): Promise<AddressInterface[]> {
      const data = await this.$axios.$post(`/v1/search/address`, {
        input
      });

      return data.data.map((a: any) => {
        return {
          title: a.city,
          fullTitle: formatAddressInput(a),
          country: a.country,
          country_code: a.country_code,
          city: a.city,
          street: a.street,
          zipcode: a.zipcode,
          houseNumber: a.houseNumber,
          addition: a.addition
        } as AddressInterface
      })
    }
  }
});
