
import Vue from 'vue';

export default Vue.extend({
  name: 'Button',
  props: {
    className: {
      type: String,
      default: 'bg-primary text-white'
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    }
  },
  methods: {
    emitClick(e: Event) {
      this.$emit('click', e);
    }
  }
});
