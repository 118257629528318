
import Vue, {PropOptions} from 'vue'
import {AddressInterface} from '~/components/filter/address-search.vue'

export default Vue.extend({
  name: 'FilterAddressBlock',
  props: {
    address: {
      type: Object,
      required: true,
    } as PropOptions<AddressInterface>
  },
  methods: {
    selectAddress(address: AddressInterface) {
      this.$emit('selectAddress', address);
    }
  }
});
