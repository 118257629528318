import { render, staticRenderFns } from "./base-filter-card.vue?vue&type=template&id=625b7fc6"
import script from "./base-filter-card.vue?vue&type=script&lang=ts"
export * from "./base-filter-card.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UiIconChevronLeft: require('/vercel/path0/components/ui/icon/chevron-left.vue').default,UiIconClose: require('/vercel/path0/components/ui/icon/close.vue').default,UiCard: require('/vercel/path0/components/ui/card.vue').default})
