import moment from 'moment'
import {StateFilters} from '~/util/format-filter-data'
import {isArray} from 'lodash'
import Vue from 'vue'

export function updateQueryParams(payload: StateFilters) {
  const query: any = {}

  if (payload.address) {
    Object.keys(payload.address).forEach(key => {
      // @ts-ignore
      query[`a${key}`] = payload.address[key] ?? ''
    })

    if (payload.address.fullTitle) {
      query['location_search'] = payload.address.fullTitle
    }
  }

  if (payload.page) {
    query.page = payload.page.toString(10)
  }

  if (payload.calendar) {
    query['date'] = moment(payload.calendar).format('DD-MM-YYYY')
    query.calendar = moment(payload.calendar).format('YYYY-MM-DD')
  }

  if (payload.person) {
    query.guests = payload.person.toString(10)
  }

  if (payload.dynamicFilters) {
    Object.keys(payload.dynamicFilters).forEach(key => {
      if (!payload.dynamicFilters[key]) {
        return;
      }

      let value;
      if (isArray(payload.dynamicFilters[key])) {
        // @ts-ignore
        value = payload.dynamicFilters[key].map(s => s.key).join(',')
      } else {
        value = payload.dynamicFilters[key].toString(10)
      }

      query[key] = value
    })
  }

  if (payload.selectedExtras && payload.selectedExtras.length > 0) {
    query.selectedExtras = payload.selectedExtras.map(s => `${s.id}-${s.quantity}`).join(',')
  }

  return query
}

export function parseQueryParams(queryParams: { [key: string]: string }): StateFilters {
  const response: StateFilters = {
    address: undefined,
    calendar: undefined,
    person: 0,
    page: 0,
    dynamicFilters: {},
    selectedExtras: [],
  }

  if (queryParams.atitle) {
    // @ts-ignore
    response.address = {};
    ['title', 'fullTitle', 'country', 'country_code', 'city', 'street', 'zipcode', 'houseNumber', 'addition'].forEach(item => {
      if (queryParams[`a${item}`]) {
        // @ts-ignore
        response.address[item] = queryParams[`a${item}`]
      } else {
        // @ts-ignore
        response.address[item] = ''
      }

      delete queryParams[`a${item}`]
    })
  }

  if (queryParams.page) {
    response.page = parseInt(queryParams.page, 10)
    delete queryParams.page
  }

  if (queryParams.calendar) {
    response.calendar = new Date(queryParams.calendar as string)
    delete queryParams.calendar
  }

  if (queryParams.guests) {
    response.person = parseInt(queryParams.guests as string, 10)
    delete queryParams.guests
  }

  // Legacy
  if (queryParams['number_of_guests']) {
    response.person = parseInt(queryParams.number_of_guests as string, 10)
    delete queryParams['number_of_guests']
  }

  // Legacy
  if (queryParams['_number_of_guests']) {
    response.person = parseInt(queryParams._number_of_guests as string, 10)
    delete queryParams['_number_of_guests']
  }

  if (queryParams['location_search']) {
    if (!response.address) {
      response.address = {}
      response.address['fullTitle'] = queryParams['location_search']
    }

    delete queryParams['location_search']
  }

  // Legacy
  if (queryParams.date_range) {
    response.calendar = moment(queryParams.date_range as string, 'DD-MM-YYYY').toDate()
    delete queryParams.date_range
  }

  if (queryParams.date) {
    response.calendar = moment(queryParams.date as string, 'DD-MM-YYYY').toDate()
    delete queryParams.date
  }

  if (queryParams.selectedExtras) {
    response.selectedExtras = queryParams.selectedExtras.split(',').map(s => ({
      id: parseInt(s.split('-')[0]),
      quantity: parseInt(s.split('-')[1])
    }))
    delete queryParams.selectedExtras
  }

  Object.keys(queryParams).forEach(key => {
    const value = queryParams[key]
    if (key === 'super_chef') {
      Vue.set(response.dynamicFilters, key, value === 'true')
    } else if (key === 'offer') {
      Vue.set(response.dynamicFilters, key, value === 'true')
    } else if (key === 'price_min' || key === 'price_max') {
      Vue.set(response.dynamicFilters, key, parseInt(value))
    } else if (key === 'title') {
      Vue.set(response.dynamicFilters, key, value)
    } else {
      // Explicit declaration to prevent unwanted pushes, e.g. from utm_campaign
      const allowedKeys = [
        'kitchen-type',
        'listing_feature',
        'listing_category',
        'specialization',
        'gelegenheden',
      ]

      if (value) {
        if (allowedKeys.indexOf(key) !== -1) {
          Vue.set(response.dynamicFilters, key, value.split(',').map(s => ({
            key: s,
            value: 'No value'
          })))
        }
      }
    }
  })

  return response
}
