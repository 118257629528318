
import Vue, {VueConstructor} from 'vue'
import QueryMixin from '~/mixins/query.mixin'

export interface AddressInterface {
  title: string;
  fullTitle: string;
  country: string;
  country_code: string;
  city: string;
  street: string;
  zipcode: string;
  houseNumber: string;
  addition: string;
}

export default (Vue as VueConstructor<Vue & InstanceType<typeof QueryMixin>>).extend({
  name: 'FilterAddressSearch',
  mixins: [QueryMixin],
  props: {
    address: {
      type: String
    }
  },
  data() {
    const addresses: AddressInterface[] = []

    return {
      addresses,
      addSearchTimeout: -1,
      loading: false,
    }
  },
  watch: {
    async address(address: string) {
      if (address.length > 0) {
        this.loading = true

        if (this.addSearchTimeout > -1) {
          clearTimeout(this.addSearchTimeout)
        }

        this.addSearchTimeout = setTimeout(async () => {
          try {
            this.addresses = await this.searchAddress(address);
          } catch (e) {
            console.error(e)
          } finally {
            this.loading = false
          }
        }, 500) as any as number
      }
    }
  },
  methods: {
    selectAddress(address: AddressInterface) {
      this.$emit('selectAddress', address)
    }
  }
})
