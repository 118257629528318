
import Vue, {VueConstructor} from 'vue'
import ResponsiveMixin from '~/mixins/responsive.mixin'
import {EVENT_SEARCH} from "~/util/event-bus";

export default (Vue as VueConstructor<Vue & InstanceType<typeof ResponsiveMixin>>).extend({
  name: 'BaseFilterCard',
  mixins: [ResponsiveMixin],
  data() {
    return {
      isOpenLocal: false
    }
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    showPrevious: {
      type: Boolean,
      default: false
    },
    showHeader: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: ''
    }
  },
  watch: {
    $props: {
      handler(props: { isOpen: boolean }) {
        setTimeout(() => {
          this.isOpenLocal = props.isOpen
        }, 100)
      },
      deep: true,
      immediate: true
    },
    isOpenLocal(val: boolean) {
      this.$emit('openChange', val)
    }
  },
  methods: {
    clickPrevious() {
      this.$emit('previous')
    },
    closeIfOpen(e: Event) {
      // Do not enable when mobile mode
      if (this.isMobile) {
        return
      }

      if (
        e.target === this.$refs.openToggle
        || e.target === this.$refs.input
        || !this.$refs.openToggle
        || (this.$refs.openToggle as Node).contains(e.target as Node)
        || !this.$refs.input
        || (this.$refs.input as Node).contains(e.target as Node)
      ) {
        return
      }

      if (this.isOpenLocal) {
        this.isOpenLocal = false

        // Trigger search when closing the filter and not on home
        if (this.$route.path !== '/') {
          this.$nuxt.$emit(EVENT_SEARCH, {});
        }
      }
    }
  }
})
