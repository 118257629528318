
import Vue, {VueConstructor} from 'vue'
import AddressSearch, {AddressInterface} from '~/components/filter/address-search.vue'
import AddressBlock from '~/components/filter/address-block.vue'
import BaseFilter from '~/mixins/filter.mixin'
import ResponsiveMixin from '~/mixins/responsive.mixin'

export const RECENT_ADDRESS_CACHE_KEY = 'RECENT_ADDRESS_CACHE_KEY'

export default (Vue as VueConstructor<Vue & InstanceType<typeof BaseFilter> & InstanceType<typeof ResponsiveMixin>>).extend({
  name: 'FilterAddress',
  mixins: [BaseFilter, ResponsiveMixin],
  components: {
    AddressBlock,
    AddressSearch
  },
  data(): {
    address: string,
    addressSelected: AddressInterface | undefined
  } {
    return {
      address: '',
      addressSelected: undefined
    }
  },
  watch: {
    value: {
      handler(val: AddressInterface | undefined): void {
        if (val) {
          this.addressSelected = val;
        }
      },
      immediate: true,
    },
    isOpen: {
      handler(state) {
        if (state) {
          this.address = ''

          setTimeout(() => {
            ((this.$refs.searchInput as Vue).$el as HTMLElement).focus()
          }, 10)
        }
      }
    }
  },
  computed: {
    recentAddresses(): AddressInterface[] {
      let addresses: AddressInterface[] = []

      try {
        addresses = JSON.parse(localStorage.getItem(RECENT_ADDRESS_CACHE_KEY) ?? '[]')
      } catch (e) {
        // Parse failed so no new addresses
      }

      return addresses
    }
  },
  methods: {
    selectAddress(address: AddressInterface) {
      this.addressSelected = address
      this.address = ''

      this.updateValue(this.addressSelected as AddressInterface)
      this.addToRecentAddress(address)

      if (!this.isMobile) {
        this.nextStep();
      }
    },
    nextStep() {
      this.clickNext()
    },
    addToRecentAddress(address: AddressInterface) {
      const newList: AddressInterface[] = this.recentAddresses

      if (newList.filter(a => a.title === address.title).length > 0) {
        // Skip if already in list
        return
      }

      if (newList.length >= 3) {
        newList.pop()
      }

      newList.unshift(address)
      localStorage.setItem(RECENT_ADDRESS_CACHE_KEY, JSON.stringify(newList))
    }
  }
})
