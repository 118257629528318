import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      clientWidth: 300,
      clientHeight: 1000
    }
  },
  mounted() {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', this.handleResize);
      this.handleResize();
    }
  },
  destroyed() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.handleResize);
    }
  },
  computed: {
    isMobile(): boolean {
      return process.browser ? this.clientWidth < 900 : true;
    }
  },
  methods: {
    handleResize() {
      if (typeof document !== 'undefined') {
        this.clientWidth = document.body.clientWidth;
        this.clientHeight = document.body.clientHeight;
      }
    }
  }
});
