
import Vue from 'vue'

export default Vue.extend({
  name: 'OutlineButton',
  props: {
    className: {
      type: String,
      default: ''
    }
  },
  methods: {
    emitClick(e: Event) {
      this.$emit('click', e);
    }
  }
})
