
import Vue from 'vue'

export default Vue.extend({
  name: 'Input',
  props: {
    value: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    }
  },
  methods: {
    click(e: Event) {
      this.$emit('click', e)
    },
    inputChange(input: string) {
      this.$emit('input', input)
    }
  }
})
