import Vue, {PropOptions} from 'vue'
import {AddressInterface} from '~/components/filter/address-search.vue'

export default Vue.extend({
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    showFilterEscape: {
      type: Boolean,
      default: true,
    },
    value: {
      default: ''
    } as PropOptions<string | number | AddressInterface>
  },
  methods: {
    updateValue(value: string | number | AddressInterface) {
      this.$emit('input', value)
    },
    clickNextAndReset() {
      this.$emit('input', undefined);
      this.clickNext();
    },
    clickNext() {
      this.$emit('next')
    },
    clickPrevious() {
      this.$emit('previous')
    },
    openChange(state: boolean) {
      this.$emit('openChange', state)
    }
  }
})
